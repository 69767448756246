import {
  Box,
  Typography,
  IconButton,
  TextField,
  Snackbar,
  Alert,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import React, { useEffect, useState, useRef } from "react";
import colors from "../../../Constants/colors";
import { ChatBoxMessagesStyles, textfieldLayout } from "./DeployedBotsStyles";
import { ArrowUpward } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { getUserBotMemory } from "../../../features/auth/authSlice";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

const ChatBot = ({ selectedBot, handleBackClick }) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [chatHistoryLoading, setChatHistoryLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [generatingMessage, setGeneratingMessage] = useState(false);
  const [displayedMessage, setDisplayedMessage] = useState("");

  const messageEndRef = useRef(null);
  const dispatch = useDispatch();
  const typingSpeed = 1000;
  const tempMessageRef = useRef(""); // Ref to store the temporary message

  const { user, botMemory, isSuccess } = useSelector((state) => state.auth);
  const { organization } = useSelector((state) => state.organization);

  const user_id = user?.user?._id["$oid"];
  const bot_id = selectedBot.id;
  const orgId = organization?._id["$oid"];

  // Function to scroll to the bottom of the messages
  const scrollToBottom = () => {
    messageEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  // Scroll to the bottom whenever messages change
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  // Fetch bot memory if the selected bot has memory
  useEffect(() => {
    if (selectedBot?.have_memory) {
      const data = { user_id, bot_id };
      setChatHistoryLoading(true);
      setSnackbarMessage("Getting Chat History...");
      setSnackbarOpen(true);
      dispatch(getUserBotMemory(data));
    }
  }, [selectedBot]);

  useEffect(() => {
    if (isSuccess && botMemory.length > 0) {
      const memory = botMemory.find(
        (memory) => memory.bot_id === selectedBot.id
      );
      if (memory && memory.memory && memory.memory.messages) {
        const formattedMessages = memory.memory.messages
          .map((msg) => [
            { sender: "user", text: msg.USER },
            {
              sender: "bot",
              text: msg.AI ? msg.AI : "AI successfully Retrieved Documents.",
            },
          ])
          .flat();
        setMessages(formattedMessages);
      }
      setChatHistoryLoading(false);
      setSnackbarMessage("");
      setSnackbarOpen(false);
    }
  }, [botMemory, selectedBot]);

  const typewriterEffect = (contentArray) => {
    setGeneratingMessage(true);
    let currentIndex = 0;
    tempMessageRef.current = ""; // Reset to an empty string

    const displayNextContent = () => {
      if (currentIndex < contentArray.length) {
        const content = contentArray[currentIndex];

        if (content === undefined || content === null) {
          console.warn(
            "Skipping undefined or null content at index:",
            currentIndex
          );
          currentIndex++;
          displayNextContent();
          return;
        }

        console.log("Displaying content:", content);

        // Append the next part of the message (Markdown text) with line breaks for proper parsing
        tempMessageRef.current += `${content}\n\n`;

        currentIndex++;

        // Update the last bot message in the messages array progressively
        setMessages((prevMessages) => {
          const updatedMessages = [...prevMessages];
          updatedMessages[updatedMessages.length - 1].text = (
            <ReactMarkdown remarkPlugins={[remarkGfm]}>
              {tempMessageRef.current}
            </ReactMarkdown>
          );
          return updatedMessages;
        });

        // Display the next part of the message after a delay
        if (
          Object.keys(selectedBot?.default_flow) === 1 &&
          selectedBot?.default_flow?.retrieval
        ) {
          setTimeout(displayNextContent, 0);
        } else {
          setTimeout(displayNextContent, 150);
        }
      } else {
        setGeneratingMessage(false); // Typing complete
      }
    };

    displayNextContent();
  };

  const handleSendMessage = async () => {
    if (newMessage.trim() === "") return;

    // Add the user's message to the conversation
    setMessages((prevMessages) => [
      ...prevMessages,
      { sender: "user", text: newMessage },
    ]);

    setNewMessage("");
    setIsLoading(true);

    // Add a temporary placeholder message for the bot's "Generating..." response
    setMessages((prevMessages) => [
      ...prevMessages,
      { sender: "bot", text: "Generating" }, // Temporary message
    ]);
    const token = JSON.parse(localStorage.getItem("token"));
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    const data = {
      flow: {
        Retrieval: selectedBot.default_flow.retrieval,
        Generation: selectedBot.default_flow.generation,
      },
      query: newMessage,
    };

    try {
      const response = await fetch(
        `/api/eval_bot/chatend2end/retrieval/${orgId}/${user_id}/${bot_id}`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // Clear the bot's placeholder message for typewriter effect
      setMessages((prevMessages) => {
        const updatedMessages = [...prevMessages];
        updatedMessages[updatedMessages.length - 1].text = ""; // Clear placeholder
        return updatedMessages;
      });

      const reader = response.body.getReader();
      const decoder = new TextDecoder("utf-8");
      let botMessage = "";

      // Read the stream
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        // Decode the chunk and append it to the bot's message
        const chunk = decoder.decode(value, { stream: true });
        botMessage += chunk;

        if (
          !selectedBot.default_flow.generation ||
          selectedBot.default_flow.generation.length === 0
        ) {
          const { valid, data } = validateAndFixJson(botMessage);
          console.log(valid, data);
          if (valid) {
            // Extract the answer field
            botMessage = data.answer;
          }
        }

        // Update the bot's message in real-time with Markdown rendering
        setMessages((prevMessages) => {
          const updatedMessages = [...prevMessages];
          updatedMessages[updatedMessages.length - 1].text = (
            <ReactMarkdown remarkPlugins={[remarkGfm]}>
              {botMessage}
            </ReactMarkdown>
          );
          return updatedMessages;
        });
      }

      setGeneratingMessage(false); // Typing complete
    } catch (error) {
      console.error("Error sending message:", error);
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: "bot", text: "Error fetching response" },
      ]);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage();
    }
  };

  const validateAndFixJson = (jsonString) => {
    try {
      // Escape unescaped double quotes inside string values
      jsonString.replace(/(?<!\\)"/g, '\\"');
      console.log("jsonString", jsonString);
      // Replace single quotes with double quotes
      jsonString = jsonString.replace(
        "'answer': 'Document",
        '"answer": "Document'
      );
      jsonString = jsonString.replace("'queries':", '"queries":');
      console.log("jsonString", jsonString);
      // Validate JSON
      return { valid: true, data: JSON.parse(jsonString) };
    } catch (error) {
      // Attempt to fix common issues
      console.warn("Invalid JSON detected, attempting to fix:", error.message);

      // Check if the string might be incomplete and append closing braces
      const openBraces = (jsonString.match(/{/g) || []).length;
      const closeBraces = (jsonString.match(/}/g) || []).length;
      console.log("jsonString", openBraces, closeBraces);

      if (openBraces > closeBraces) {
        jsonString += "}".repeat(openBraces - closeBraces); // Add missing closing braces
      }

      // Attempt to validate again
      try {
        return { valid: true, data: JSON.parse(jsonString) };
      } catch (finalError) {
        console.error("Failed to fix JSON:", finalError.message);
        return { valid: false, data: null };
      }
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        width: "100%",
        backgroundColor: colors.extremeBlue,
        my: "20px",
        boxShadow: `0px 0px 70px ${colors.secondaryBlue}`,
        py: 2,
        px: 4,
        overflowY: "auto",
        height: "90vh",
        borderRadius: "8px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          borderBottom: `1px solid ${colors.extremeWhite}`,
          pb: 2,
          my: 1,
        }}
      >
        <IconButton
          onClick={handleBackClick}
          sx={{
            color: colors.extremeWhite,
            backgroundColor: colors.primaryBlue,
            "&:hover": {
              backgroundColor: colors.tertiaryBlue,
            },
            boxShadow: `0px 4px 6px ${colors.secondaryBlue}`,
            marginRight: 2,
          }}
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography
          variant="h5"
          sx={{
            flex: 1,
            textAlign: "center",
            color: colors.extremeWhite,
          }}
        >
          Chat with {selectedBot.name}
        </Typography>
      </Box>

      <Box sx={ChatBoxMessagesStyles}>
        {(messages || []).map((message, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              justifyContent:
                message.sender === "user" ? "flex-end" : "flex-start",
              mb: 2,
            }}
          >
            <Box
              sx={{
                maxWidth: "70%",
                padding: "10px 15px",
                borderRadius: "15px",
                backgroundColor:
                  message.sender === "user"
                    ? colors.primaryBlue
                    : colors.textFieldBackground,
                color: colors.extremeWhite,
                boxShadow: `0px 4px 6px ${colors.secondaryBlue}`,
                // "&::after": message.text === "Generating" && {
                //   content: "'...'",
                //   display: "inline-block",
                //   animation: "dots 1.5s steps(1, end) infinite",
                // },
                // "@keyframes dot-blink": message.text === "Generating" && {
                //   "0%": { content: '"."' }, // 1 dot
                //   "33%": { content: '".."' }, // 2 dots
                //   "66%": { content: '"..."' }, // 3 dots
                //   "100%": { content: '""' }, // No dots (restarts)
                // },
              }}
            >
              {/* Render the message content */}
              {message.sender !== "user" &&
              !generatingMessage &&
              typeof message.text === "string" ? (
                <ReactMarkdown remarkPlugins={[remarkGfm]}>
                  {message.text}
                </ReactMarkdown>
              ) : (
                message.text
              )}
            </Box>
          </Box>
        ))}
        <div ref={messageEndRef} />
      </Box>

      <Box sx={{ display: "flex", alignItems: "center" }}>
        <TextField
          variant="outlined"
          placeholder="Type your message..."
          fullWidth
          sx={textfieldLayout}
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyDown={handleKeyDown}
          disabled={isLoading || chatHistoryLoading || generatingMessage}
        />
        <IconButton
          sx={{
            color: colors.extremeWhite,
            backgroundColor: colors.primaryBlue,
            "&:hover": {
              backgroundColor: colors.tertiaryBlue,
            },
            "&.Mui-disabled": {
              backgroundColor: colors.darkGray,
              color: colors.lightGray,
            },
          }}
          onClick={handleSendMessage}
          disabled={isLoading || chatHistoryLoading || generatingMessage}
        >
          <ArrowUpward />
        </IconButton>
      </Box>
      <Snackbar
        open={snackbarOpen}
        // autoHideDuration={2000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="info"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ChatBot;
