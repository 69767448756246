// src/utils/defaultPrompts.js

export const COMPLEX_TO_SIMPLE_QUERY_PROMPT = `""
    You are an AI language model assistant that is the master of query generation.
    Your Job is to take a complex query and deconstruct it into several simpler queries.
    Each simplified query should focus exclusively on one distinct concept found in the original query.
    Input Specification:
    Complex Query: A single, detailed query that might contain multiple intertwined concepts.
    Output Specification:
    Simple Queries: A list of queries where each query addresses a single, isolated concept from the complex query.
    Process:
    Identify Concepts: Analyze the complex query to identify distinct concepts. This involves parsing the query for keywords, phrases, choices, and their contextual dependencies. If a question is a multiple-choice question, consider each option as a standalone concept.
    Generate Queries: For each identified concept, generate a new query. Ensure that each new query:
    - Contains only elements relevant to its respective concept.
    - Is formulated clearly enough to be understood and processed independently.
    Always provide these new questions separated by newlines.
    Complex Query: {query}
""`;

export const QUERY_EXPANSION = `""
    You are an AI language model assistant that is the master of query generation.
    Your Job is to take a query and expand it into a more robust and detailed query.
    The detailed query should focus on each distinct concept found in the original query and explain it in a better and descriptive way.
    Input Specification:
    Input Query: A single, detailed query that might contain multiple intertwined concepts.
    Output Specification:
    Output Query: A detailed query that addresses all concepts from the Input query in a clearer and more descriptive fashion.
    Process:
    Identify Concepts: Analyze the Input query to identify distinct concepts. This involves parsing the query for keywords, phrases, choices, and their contextual dependencies. If a question is a multiple-choice question, consider each option as a standalone concept.
    Generate Queries: For each identified concept, expand it to be clearer and more detailed. Ensure that the new query:
    - Contains all the information in the Input query but in a more detailed fashion.
    - Is formulated clearly enough to be understood and processed.
    Simply return the new query.
    Input Query: {query}
    Output Query: <<Your new query>>
""`;

export const MULTI_QUERY_PROMPT = `""
    You are an AI language model assistant. Your task is to generate 3 different versions of the given user question to retrieve relevant documents from a vector database.
    By generating multiple perspectives on the user question, your goal is to help the user overcome some of the limitations of distance-based similarity search.
    Provide these alternative questions separated by newlines.
    Original question: {query}
""`;

export const HYDE_PROMPT = `""
    Answer the following query:
    Query: {query}
""`;

export const ITERATIVE_DECONSTRUCTION = `""
    Help break down complex problems into step-by-step search queries to gather all required information. Here's a structured process to follow:
    1. If previous helper query and context is not provided, create the first helper query
    2. If previous helper queries and contexts are provided, use the context to create the next helper query that targets missing information.
    3. Up to 5 helper queries can be created.
    4. If all data is found or 5 queries are reached, respond with "**stop**".
    5. Respond in the following format and do not write anything else
        sub-query: <the created sub-query or **stop** goes here>
    Notes:
    Each helper query should maintain a logical progression towards the end goal
    Avoid repeating previous queries
    Only provide the next helper query - don't answer previous queries
    Helper Query: {query}
    Context: {context}
    Your Answer : <<helper_query>>
""`;

export const CONTEXTUAL_RETRIEVAL_PROMPT = `""
    <document>
    {complete_document}
    </document>

    Here is the chunk we want to situate within the whole document
    <chunk>
    {text}
    </chunk>

    Please give a short succinct context to situate this chunk within the overall document for the purposes of improving search retrieval of the chunk.
    Answer only with the succinct context and nothing else.
""`;
