import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Typography,
  IconButton,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Tooltip,
  // Snackbar,
  // Alert,
} from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import axios from "axios";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { ChatBoxMessagesStyles, textfieldLayout } from "./CustomerBotStyles";
import colors from "../../Constants/colors";

const CustomerBot = () => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  // const [snackbarOpen, setSnackbarOpen] = useState(false);
  // const [snackbarMessage, setSnackbarMessage] = useState("");
  const [generatingMessage, setGeneratingMessage] = useState(false);
  const [selectedOption, setSelectedOption] = useState("only_bot");

  const messageEndRef = useRef(null);

  const scrollToBottom = () => {
    messageEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const apiKeysByBotId = {
    "675821d5579758c8891bdf96":
      "qlp_gAAAAABnWHwp-UL-MV2YqmHVYeg4XWGJLjfRSHc1dGRcM2O12I_RXNOauV_x8DtIvzTUWQ_P0vXcJaejWy83Ra6ImgX35BNm5-jx_ocHl3_1sJ7YpJIRdls=qlpgAAAAABnWHwpQ7uoB6h1mv4_rDKZ4Ej6GPypnZaoFtH4TVhV3NkzqI6meGDg2f9vCjkgQMv8AcQzImH1pmr7Etq58vQwQ3t6U_EBrFowRf_nkuAWiifwNYA=",
    "6758329b021ebb8c4b9433cb":
      "qlp_gAAAAABnWH3av_864oswEvnxoVObyvlfWNQ2XrE6SF4FU6vhTkm2gva_k_o0xoMs6HUBZaGnyKBsHvQz96tni0Q3I95auy98MSmUewqXgnYIHP7aHaD8gzA=qlpgAAAAABnWH3a2cKQXmPICKK5m58PFpf6NpxeKDZrBTCZzqANjlXmKN9J-IDAbzbpu8O9LTQrQ0aWnTe_KhL6hi494pCEIDpey9slljpwlVTvaRV2WwQatnE=",
    "675835b6579758c8891bdfd6":
      " qlp_gAAAAABnWH44XPImCpPiA-D5PC_B7Sl3BS3uYKFnxK2bS2628xprMhXrhmVaX8vP_Zvk6ZP0MIPh_BFsZjBaFX1s6QG8BHjd6v6fjEMH-ZykL7zOAEys_ko=qlpgAAAAABnWH44iam5x_NoJLqnqxk4vKpuH-f2kGB1VcJsjN4g9U30hRnGVLl51-NdINoxXB4DElIObz9uvuL857-DbAr9Bh5zNCpuzVZGiD5EP_TxgcY5TGg=",
    "67583ea80f258c4ae8378dd7":
      "qlp_gAAAAABnWH662GH3C85dN5609wFcRiRlQx6g_XdoUk3SEIhyswim7b5gHCBW7DshVXV2TQ59yY1b_n6WduKyKHbtyTBWdw5gPXop7jgM2GKMrHJY58uzQCg=qlpgAAAAABnWH66NCbohKF54UHkZqlZWOrMm9YOEGH0u651NXJGWFztAzsN-6kuJJnj411hd9KpPMlaDmEQqF1kvUY09WB2kX3TnDAkxTtURX77sIqx6rQGf5Y=",
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  // const handleSnackbarClose = () => {
  //   setSnackbarOpen(false);
  // };

  // Scroll to the bottom whenever messages change

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleSendMessage = async () => {
    if (newMessage.trim() === "") return;

    // Add the user's message to the conversation
    setMessages((prevMessages) => [
      ...prevMessages,
      { sender: "user", text: newMessage },
    ]);

    const userMessageIndex = messages.length; // Track the index of the user's message

    setNewMessage("");
    setIsLoading(true);

    // Add a placeholder for the bot's response
    setMessages((prevMessages) => [
      ...prevMessages,
      { sender: "bot", text: "Generating response..." }, // Single message placeholder
    ]);

    setGeneratingMessage(true);

    const url = "https://demo.queryloop-ai.com/api/developer/use";

    const apiKeys = [
      apiKeysByBotId["6758329b021ebb8c4b9433cb"], // First response key
      apiKeysByBotId["675835b6579758c8891bdfd6"], // Second response key
      apiKeysByBotId["67583ea80f258c4ae8378dd7"], // Third response key
    ];

    const payload = {
      query: newMessage,
      verification_token:
        "24fe260bb74d464b0e03a463c7cd6e6ea7237369d8f9de48c227bd7b9b8b5beb",
    };

    try {
      if (selectedOption === "bot_group") {
        // Fetch all responses in parallel
        const fetchPromises = apiKeys.map((apiKey) =>
          fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              apiKey: apiKey,
            },
            body: JSON.stringify(payload),
          })
        );

        const responses = await Promise.all(fetchPromises);

        // Process the first bot's response with streaming
        const firstReader = responses[0].body.getReader();
        const firstDecoder = new TextDecoder("utf-8");
        let firstBotMessage = "";

        // Initially show only response 1 while it's being streamed
        while (true) {
          const { done, value } = await firstReader.read();
          if (done) break;
          firstBotMessage += firstDecoder.decode(value, { stream: true });

          const messageToDisplay = `**Deephaven Assistant Response:**\n${firstBotMessage}`;
          setMessages((prevMessages) => {
            const updatedMessages = [...prevMessages];
            updatedMessages[userMessageIndex + 1].text = (
              <ReactMarkdown remarkPlugins={[remarkGfm]}>
                {messageToDisplay}
              </ReactMarkdown>
            );
            return updatedMessages;
          });
        }

        // Once the first response is done, stream the second response
        const secondReader = responses[1].body.getReader();
        const secondDecoder = new TextDecoder("utf-8");
        let secondBotMessage = "";

        // Now show response 1 and start showing response 2
        while (true) {
          const { done, value } = await secondReader.read();
          if (done) break;
          secondBotMessage += secondDecoder.decode(value, { stream: true });

          const messageToDisplay = `**Deephaven Assistant Response:**\n${firstBotMessage}\n\n*Onslow Bay Assistant Response:**\n${secondBotMessage}`;
          setMessages((prevMessages) => {
            const updatedMessages = [...prevMessages];
            updatedMessages[userMessageIndex + 1].text = (
              <ReactMarkdown remarkPlugins={[remarkGfm]}>
                {messageToDisplay}
              </ReactMarkdown>
            );
            return updatedMessages;
          });
        }

        // Once the second response is done, stream the third response
        const thirdReader = responses[2].body.getReader();
        const thirdDecoder = new TextDecoder("utf-8");
        let thirdBotMessage = "";

        while (true) {
          const { done, value } = await thirdReader.read();
          if (done) break;
          thirdBotMessage += thirdDecoder.decode(value, { stream: true });

          const messageToDisplay = `**Deephaven Assistant Response:**\n${firstBotMessage}\n\n**Onslow Bay Assistant Response:**\n${secondBotMessage}\n\n**Verus Assistant Response:**\n${thirdBotMessage}`;
          setMessages((prevMessages) => {
            const updatedMessages = [...prevMessages];
            updatedMessages[userMessageIndex + 1].text = (
              <ReactMarkdown remarkPlugins={[remarkGfm]}>
                {messageToDisplay}
              </ReactMarkdown>
            );
            return updatedMessages;
          });
        }

        // After all three are done, the final message is fully displayed.
      } else {
        // Single API call for "only_bot"
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            apiKey: apiKeysByBotId["675821d5579758c8891bdf96"], // Use the first API key
          },
          body: JSON.stringify(payload),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const reader = response.body.getReader();
        const decoder = new TextDecoder("utf-8");
        let botMessage = "";

        while (true) {
          const { done, value } = await reader.read();
          if (done) break;

          botMessage += decoder.decode(value, { stream: true });

          // Update the single bot message using ReactMarkdown
          setMessages((prevMessages) => {
            const updatedMessages = [...prevMessages];
            updatedMessages[updatedMessages.length - 1].text = (
              <ReactMarkdown remarkPlugins={[remarkGfm]}>
                {botMessage}
              </ReactMarkdown>
            );
            return updatedMessages;
          });
        }
      }
    } catch (error) {
      console.error("Error sending message:", error);
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: "bot", text: "Error fetching response" },
      ]);
    } finally {
      setIsLoading(false);
      setGeneratingMessage(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage();
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        height: "100vh",
        px: 8,
        bgcolor: "white",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "95%",
          backgroundColor: colors.extremeBlue,
          mt: "20px",
          boxShadow: `0px 0px 70px ${colors.secondaryBlue}`,
          py: 2,
          px: 4,
          height: "93vh",
          borderRadius: "8px",
          flex: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            borderBottom: `1px solid ${colors.extremeWhite}`,
            pb: 2,
            my: 1,
          }}
        >
          {/* <IconButton
          onClick={handleBackClick}
          sx={{
            color: colors.extremeWhite,
            backgroundColor: colors.primaryBlue,
            "&:hover": {
              backgroundColor: colors.tertiaryBlue,
            },
            boxShadow: `0px 4px 6px ${colors.secondaryBlue}`,
            marginRight: 2,
          }}
        >
          <ArrowBackIcon />
        </IconButton> */}
          <Typography
            variant="h5"
            sx={{
              flex: 1,
              textAlign: "center",
              color: colors.extremeWhite,
            }}
          >
            Chat with Homey
          </Typography>
        </Box>

        <Box sx={ChatBoxMessagesStyles}>
          {(messages || []).map((message, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                justifyContent:
                  message.sender === "user" ? "flex-end" : "flex-start",
                mb: 2,
              }}
            >
              <Box
                sx={{
                  maxWidth: "70%",
                  padding: "10px 15px",
                  borderRadius: "15px",
                  backgroundColor:
                    message.sender === "user"
                      ? colors.primaryBlue
                      : colors.textFieldBackground,
                  color: colors.extremeWhite,
                  boxShadow: `0px 4px 6px ${colors.secondaryBlue}`,
                  // "&::after": message.text === "Generating" && {
                  //   content: "'...'",
                  //   display: "inline-block",
                  //   animation: "dots 1.5s steps(1, end) infinite",
                  // },
                  // "@keyframes dot-blink": message.text === "Generating" && {
                  //   "0%": { content: '"."' }, // 1 dot
                  //   "33%": { content: '".."' }, // 2 dots
                  //   "66%": { content: '"..."' }, // 3 dots
                  //   "100%": { content: '""' }, // No dots (restarts)
                  // },
                }}
              >
                {/* Render the message content */}
                {message.sender !== "user" &&
                !generatingMessage &&
                typeof message.text === "string" ? (
                  <ReactMarkdown remarkPlugins={[remarkGfm]}>
                    {message.text}
                  </ReactMarkdown>
                ) : (
                  message.text
                )}
              </Box>
            </Box>
          ))}
          <div ref={messageEndRef} />
        </Box>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <TextField
            variant="outlined"
            placeholder="Type your message..."
            fullWidth
            sx={textfieldLayout}
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            onKeyDown={handleKeyDown}
            disabled={isLoading || generatingMessage}
          />
          <IconButton
            sx={{
              color: colors.extremeWhite,
              backgroundColor: colors.primaryBlue,
              "&:hover": {
                backgroundColor: colors.tertiaryBlue,
              },
              "&.Mui-disabled": {
                backgroundColor: colors.darkGray,
                color: colors.lightGray,
              },
            }}
            onClick={handleSendMessage}
            disabled={isLoading || generatingMessage}
          >
            <ArrowUpwardIcon />
          </IconButton>
        </Box>
        {/* <Snackbar
          open={snackbarOpen}
          autoHideDuration={5000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity="info"
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar> */}
      </Box>
      {/* Radio Groups Section */}
      <FormControl sx={{ ml: 4, mt: 2 }}>
        <RadioGroup
          value={selectedOption}
          onChange={handleOptionChange}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Tooltip title="Queries all lender guidelines together and creates a best response">
            <FormControlLabel
              value="only_bot"
              control={<Radio />}
              label="Unified Best Response"
            />
          </Tooltip>
          <Tooltip title="Queries lender guidelines individually and generates a response as per each lender separately">
            <FormControlLabel
              value="bot_group"
              control={<Radio />}
              label="Individual Lender Responses"
            />
          </Tooltip>

          {/* <FormControlLabel
            value="only_bot_gen"
            control={<Radio />}
            label="Unified Response 2"
          /> */}
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

export default CustomerBot;
